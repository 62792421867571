import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import SliceZone from "../components/SliceZone"
import SEO from "../components/SEO"

export default function Page({ data }) {
  if (!data) return null

  const pageEdge = data.prismic.page.edges[0]
  if (!pageEdge) return null
  const content = pageEdge.node

  const navigationEdges = data.prismic.allNavigations.edges[0]
  if (!navigationEdges) return null
  const navOptions = navigationEdges.node.menu_links
  const activePageMeta = content._meta
  if (content) {
    return (
      <Layout activePageMeta={content._meta}
              navOptions={navOptions}>
        <SEO lang={activePageMeta.lang}
             title={RichText.asText(content.seo_page_title)}
             description={RichText.asText(content.seo_page_description)}
             social={content.social}/>
        <Banner className="banner-gradient text-white"
                tagLine={content.tag_line}
                headline={RichText.render(content.headline)}
                shortDescription={RichText.render(content.short_description)}/>
        <SliceZone slices={content.body} />
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query pageQuery($uid: String, $lang: String) {
  prismic {
    page: allPages(uid: $uid, lang: $lang) {
      edges {
        node {
          tag_line
          headline
          short_description
          seo_page_title
          seo_page_description
          _meta {
            id
            lang
            type
            uid
            alternateLanguages {
              lang
              type
              uid
            }
          }
          social {
            ... on PRISMIC_PageSocialGeneral_card {
              type
              label
              primary {
                title
                image
                description
              }
            }
            ... on PRISMIC_PageSocialTwitter_card {
              type
              label
              primary {
                card_type
                description
                image
                title
                twitter_handle
              }
            }
          }
          body {
            ... on PRISMIC_PageBodyProfile_photo {
              type
              label
              primary {
                section_title
              }
              fields {
                full_name
                linkedin {
                  ... on PRISMIC__ExternalLink {
                    target
                    url
                  }
                }
                position
                profile_picture
              }
            }
            ... on PRISMIC_PageBodyList_with_title {
              type
              label
              primary {
                section_title
              }
              fields {
                item
              }
            }
            ... on PRISMIC_PageBodyInfo_with_image {
              type
              label
              primary {
                section_image
                section_tagline
                section_headline
                section_description
              }
            }
            ... on PRISMIC_PageBodyInfo_with_image_left {
              type
              label
              primary {
                section_image
                section_tagline
                section_headline
                section_description
              }
            }
            ... on PRISMIC_PageBodyTiles {
              type
              label
              fields {
                tile {
                  ... on PRISMIC_Solution {
                    tagline
                    headline
                    bg_image
                    color
                    label
                    _meta {
                      id
                      lang
                      type
                      uid
                      alternateLanguages {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allNavigations(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
